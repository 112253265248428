import React, { memo } from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from 'prop-types'
import './Img.sass'

const Img = ({ src, containerClass, contain, ...rest }) => {
  const additionalClasses = containerClass ? ` ${containerClass}` : ''
  const containClass = contain ? ' contain' : ''
  const image = getImage(src)

  const _renderImage = () => {
    return image
      ? <GatsbyImage loading="eager" image={image} {...rest}/>
      : <img src={src} {...rest}/>
  }

  return (
    <div className={`imgContainer${additionalClasses}${containClass}`}>
      { _renderImage() }
    </div>
  )
}
export default memo(Img)

Img.propTypes = {
  src: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  contain: PropTypes.bool
}

Img.defaultProps = {
  containerClass: '',
  contain: false
}
