import React, { useState, useEffect } from 'react'
import Img from '../Img'
import './ToTopButton.sass'
import topBtn from '../../assets/img/top_baloon.png'

const windowHeight = typeof window !== 'undefined' && window.innerHeight

const ToTopButton = () => {
  const [isFixed, setFixed] = useState(true)
  const [isShown, setShow] = useState(false)
  const [isAnimated, setAnimated] = useState(false)
  const [footerEl, setFooterEl] = useState(null)

  const _handleClick = () => {
    setAnimated(true)
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
  const handleScroll = () => {
    const currentScrollY = window.scrollY
    if (currentScrollY === 0) {
      setAnimated(false)
    }
    if (currentScrollY >= (windowHeight / 2)) {
      setShow(true)
    } else {
      setShow(false)
    }
    // console.log(currentScrollY)
  }
  const addListener = () => {
    // console.log('add listener in top btn')
    window.addEventListener('scroll', handleScroll, { passive: true })
  }
  const resetListener = () => {
    // console.log('reset listener in top btn')
    window.removeEventListener('scroll', handleScroll)
  }

  if (typeof window !== 'undefined') {
    if (footerEl) {
      const observer = new IntersectionObserver((entry, observer) => {
        if (entry && entry[0] && entry[0].isIntersecting) {
          setFixed(false)
        } else {
          setFixed(true)
        }
      })
      observer.observe(footerEl)
    } else {
      setTimeout(() => {
        setFooterEl(document.querySelector('footer'))
      }, 1000)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      addListener()

      return resetListener
    }
  }, [])

  return (
    <button id="toTopBtn" className={`${isFixed ? 'fixed' : ''}${isShown ? ' show' : ''}${isAnimated ? ' animated' : ''}`} onClick={_handleClick}>
      <Img src={topBtn} alt="トップへの風船アイコン" title="トップへ"/>
      <span className="jp-accent">TOPへ</span>
    </button>
  )
}
export default ToTopButton
