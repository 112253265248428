import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import 'scroll-behavior-polyfill'

import Menu from '../Menu'
import Footer from '../Footer'
import ToTopButton from '../ToTopButton'

import { isComingBack, setNewVisitor } from '../../helpers'

import backgroundImage from '../../assets/img/bg.png'
import './PageWrapper.sass'

const Layout = ({ children }) => {
  useEffect(() => {
    if (!isComingBack()) setNewVisitor()
  }, [])
  return (
    <>
      <Menu />
      <div
        className="pageWrapper"
        style={{
          backgroundImage: `url("${backgroundImage}"`
        }}
      >
        <main>
          {children}
        </main>
        <ToTopButton />
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
