import React, { memo } from 'react'
import { Link } from 'gatsby'
import Img from '../Img'
import logoBlack from '../../assets/img/logo_black.svg'
import './ShopButton.sass'

const ShopButton = () => {
  return (
    <Link className="shopLink flex invisible" to="/shop">
      <div className="left">
        <Img src={logoBlack} containerClass="logoContainer" alt="まいなタイムのショップへのアイコン" title="ショップへ"/>
      </div>
      <div className="right">
        <div className="japaneseText jp-accent">オンラインショップ</div>
        <div className="englishText accent large">Online Shop</div>
      </div>
    </Link>
  )
}
export default memo(ShopButton)
