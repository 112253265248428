import React from 'react'
import { Link } from 'gatsby'
import Img from '../Img'
import ShopButton from '../ShopButton'
import logo from '../../assets/img/logo.svg'
import './Footer.sass'

const Footer = () => {
  return (
    <footer>
      <div className="innerFooter flex justify-between">
        <div className="left">
          <ShopButton />
          <nav>
            <Link className="link link--white" to="/">ホーム</Link>
            <Link className="link link--white" to="/work">制作物</Link>
            <Link className="link link--white" to="/blog">ブログ</Link>
            <Link className="link link--white" to="/msquare">ママスクエア</Link>
            <Link className="link link--white" to="/contact">お問い合わせ</Link>
          </nav>
        </div>
        <div className="right column align-center">
          <Link to="/">
            <Img src={logo} containerClass="logoContainer" alt="まいなタイムのロゴ" title="まいなタイム"/>
          </Link>
          <div className="copyright smaller">© Myna Time ALL RIGHTS RESERVED.</div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
