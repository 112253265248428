import React, { memo, useState } from 'react'
import { Link } from 'gatsby'
import Img from '../Img'
import ShopButton from '../ShopButton'
import './Menu.sass'

import mynaLogo from '../../assets/img/logo_black.svg'
import navImg from '../../assets/img/nav_img.png'
import twitterLogo from '../../assets/img/twitter.svg'
import facebookLogo from '../../assets/img/facebook.svg'
import instagramLogo from '../../assets/img/instagram.svg'

const menu = () => {
  const [isMenuOpen, toggleMenu] = useState(false)

  const toggle = () => toggleMenu(!isMenuOpen)

  const _handleLinkClick = () => {
    toggleMenu(false)
  }

  const _renderMenu = () => {
    if (!isMenuOpen) return null
    return (
      <section className="navSection flex">
        <div className="left">
          <Img src={navImg} alt="まいなタイムのメニュー写真" title="まいなタイムへようこそ"/>
        </div>
        <div className="right">
          <div className="menuLogo">
            <Img src={mynaLogo} alt="まいなタイムのロゴ" title="まいなタイム" />
          </div>
          <ul className="linkList">
            <li className="jp-accent">
              <div className="accent label">Home</div>
              <Link className="link link--red medium" onClick={_handleLinkClick} to="/">ホーム</Link>
            </li>
            <li className="jp-accent">
              <div className="accent label">Blog</div>
              <Link className="link link--red medium" onClick={_handleLinkClick} to="/blog">ブログ</Link>
            </li>
            <li className="jp-accent">
              <div className="accent label">Work</div>
              <Link className="link link--red medium" onClick={_handleLinkClick} to="/work">制作物</Link>
            </li>
            <li className="jp-accent">
              <div className="accent label">M² Square</div>
              <Link className="link link--red medium" onClick={_handleLinkClick} to="/msquare">ママスクエア</Link>
            </li>
            <li className="jp-accent">
              <div className="accent label">Contact</div>
              <Link className="link link--red medium" onClick={_handleLinkClick} to="/contact">お問い合わせ</Link>
            </li>
          </ul>
          <ShopButton />
          <div className="snsContainer flex justify-center">
            <a href="https://twitter.com/myna_time" className="snsLogo twitterLogo" target="_blank" rel="noopener noreferrer">
              <Img src={twitterLogo} alt="Twitterへの風船アイコン" title="Twitterへ"/>
            </a>
            <a href="https://www.facebook.com/mynatime/" className="snsLogo facebookLogo" target="_blank" rel="noopener noreferrer">
              <Img src={facebookLogo} alt="Facebookへの風船アイコン" title="Facebookへ"/>
            </a>
            <a href="https://www.instagram.com/myna_time/" className="snsLogo instagramLogo" target="_blank" rel="noopener noreferrer">
              <Img src={instagramLogo} alt="Instagramへの風船アイコン" title="Instagramへ"/>
            </a>
          </div>
        </div>
      </section>
    )
  }
  return (
    <nav
      className={`appNav${isMenuOpen ? ' opened' : ' closed'}`}
      // style={{
      //   backgroundImage: `url("${backgroundImage}"`
      // }}
    >
      <button className="navToggle" onClick={toggle}>
        <div className="row"><span></span><span></span></div>
        <div className="row"><span></span><span></span></div>
        <div className="row"><span></span><span></span></div>
      </button>
      { _renderMenu() }

    </nav>
  )
}

export default memo(menu)
