export const GUID = function () {
  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + '-' + s4() + s4()
}

export const isComingBack = () => {
  const id = localStorage.getItem('id')
  // console.log('id', id)
  return id
}

export const setNewVisitor = () => {
  localStorage.setItem('id', `${GUID()}_${Date.now()}`)
}
